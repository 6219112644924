// -- External libraries
import { splitProps } from 'solid-js'

import { useSeparatorAtomComponentController } from './separator-atom-component-controller.js'
import './separator-atom-component.scss'

export default function SeparatorAtomComponent(props) {
    const { mergedProps } = useSeparatorAtomComponentController(props)
    const requiredAttributes = []
    const [reqAttrs, styles, attrs] = splitProps(mergedProps, requiredAttributes, ['class'])

    const rootClasses = ['separator-atom-component']
    if (styles.class) rootClasses.push(styles.class)
    
    return (
        <hr class={rootClasses.join(' ')} {...attrs}/>
    )
}