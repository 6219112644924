// -- External libraries
import { splitProps } from 'solid-js'
import { Dynamic } from 'solid-js/web'

import { useLinkAtomComponentController } from './link-atom-component-controller.js'
import './link-atom-component.scss'

export default function LinkAtomComponent(props) {
    const { mergedProps } = useLinkAtomComponentController(props)
    const requiredAttributes = ['external']
    const [reqAttrs, styles, attrs] = splitProps(mergedProps, requiredAttributes, ['class', 'classList'])

    const rootClasses = ['link-atom-component']
    if (styles.class) rootClasses.push(styles.class)

    return (
        <Dynamic class={rootClasses.join(' ')} classList={styles.classList} component={'a'} {...attrs}>
            {attrs.children}
        </Dynamic>
    )
}