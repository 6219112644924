import BaseBrowserStore from "./base/base-browser-store"

class SessionStore extends BaseBrowserStore {
    constructor(session = {}) {
        super('session')
    }

    getAccessToken() {
        return this.get('access_token')
    }

    setAccessToken(token) {
        this.set('access_token', token)
    }

    removeAccessToken() {
        this.remove('access_token')
    }

    clear() {
        this.removeAccessToken()
    }

}

const sessionStore = new SessionStore()
export default sessionStore