// -- External libraries
import { splitProps } from 'solid-js'

import { useButtonAtomComponentController } from './button-atom-component-controller.js'
import './button-atom-component.scss'

export default function ButtonAtomComponent(props) {
    const { mergedProps } = useButtonAtomComponentController(props)
    const requiredAttributes = ['children']
    const [reqAttrs, styles, attrs] = splitProps(mergedProps, requiredAttributes, ['class'])

    const rootClasses = ['button-atom-component']
    if (styles.class) rootClasses.push(styles.class)
    
    return (
        <button class={rootClasses.join(' ')} {...attrs}>
            {reqAttrs.children}
        </button>
    )
}