import BaseBrowserStore from "./base/base-browser-store.js"

class UserStore extends BaseBrowserStore {
    constructor(user = {}) {
        super('user')
    }

    setUser(user) {
        this.setEmail(user.email)
        this.setVerifiedAt(user.verified_at)
    }

    getUser() {
        return this.get()
    }

    getEmail() {
        return this.get('email')
    }

    setEmail(email) {
        this.set('email', email)
    }

    getVerifiedAt() {
        return this.get('verified_at')
    }

    setVerifiedAt(verified_at) {
        this.set('verified_at', verified_at)
    }

    clear() {
        this.remove('email')
        this.remove('verified_at')
    
    }

}

const userStore = new UserStore()
export default userStore