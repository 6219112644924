import { lazy } from "solid-js"

export default {
    path: "/faq",
    component: lazy(() => import("../../pages/faq-page/faq-page.jsx")),
    children: [
        {
            path: '/general',
        },
        {
            path: '/bookings',
        },
        {
            path: '/pricing',
        }
    ]
}