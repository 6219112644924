import './error-modal.scss'
import { useErrorModalController } from './error-modal-controller.js'
import ButtonAtomComponent from '../../components/atoms/button-atom/button-atom-component.jsx'
import ModalOrganismComponent from '../../components/organisms/modal-organism/modal-organism-component.jsx'
import Error from '../../../models/Error.js'

export default function ErrorModal(props) {
    return (
        <ModalOrganismComponent
            class='error-modal'
            title='Error occurred'
            onClose={props.onClose}
            seamlessSections={true}
        >
            <p class="error-modal__description">
                {props.error.toString()}
            </p>
            <Show when={props.error instanceof Error}>
            <p class="error-modal__stack">
                {props.error.getStack()}
            </p>
            </Show>
            <ButtonAtomComponent class="error-modal__close-button" onClick={props.onClose}>
                Close
            </ButtonAtomComponent>
        </ModalOrganismComponent>
    )
}