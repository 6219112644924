// -- External libraries
import { Show, splitProps } from 'solid-js'

import { useCardAtomComponentController } from './card-atom-component-controller.js'
import './card-atom-component.scss'

export default function CardAtomComponent(props) {
    const { mergedProps } = useCardAtomComponentController(props)
    const requiredAttributes = ['heading', 'subheading', 'shadow']
    const [reqAttrs, styles, attrs] = splitProps(mergedProps, requiredAttributes, ['class'])

    const rootClasses = ['card-atom-component']
    if (styles.class) rootClasses.push(styles.class)
    if (reqAttrs.shadow) rootClasses.push('card-atom-component--shadow')

    return (
        <div class={rootClasses.join(' ')} {...attrs}>
            <Show when={reqAttrs.heading}>
                <h2 class='card-atom-component__heading'>
                    {reqAttrs.heading}
                </h2>
            </Show>
            <Show when={reqAttrs.subheading}>
                <div class='card-atom-component__subheading'>
                    {reqAttrs.subheading}
                </div>
            </Show>
            <div class='card-atom-component__content'>
                {attrs.children}
            </div>
        </div>
    )
}