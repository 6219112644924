// -- External libraries
import { Show, splitProps  } from 'solid-js'
import { PublicTemplateContext } from './public-template-component-context.js'; // Adjusted import

import Routes from '../../../routes/router';
import { usePublicTemplateComponentController } from './public-template-component-controller.js'

import './public-template-component.scss'
import Logo from '/assets/logo/logo.svg'
import BarAtomComponent from '../../atoms/bar-atom/bar-atom-component.jsx'
import MenuMoleculeComponent from '../../molecules/menu-molecule/menu-molecule-component.jsx'
import LinkAtomComponent from '../../atoms/link-atom/link-atom-component.jsx'
import ImageAtomComponent from '../../atoms/image-atom/image-atom-component.jsx'
import FooterOrganismComponent from '../../organisms/footer-organism/footer-organism.jsx'
import ButtonAtomComponent from '../../atoms/button-atom/button-atom-component.jsx'
import SeparatorAtomComponent from '../../atoms/separator-atom/separator-atom-component.jsx'

export default function PublicTemplateComponent(props) {
    
    const controller = usePublicTemplateComponentController(props)

    return (
        <PublicTemplateContext.Provider value={controller.value}>
            <div class={['public-template-component',controller.styles.class].join(' ')}>
                <Show when={controller.state.showHeader}>
                    <BarAtomComponent class='public-template-component__header'>
                        <ImageAtomComponent class='public-template-component__header__logo' src={Logo} />
                        <ButtonAtomComponent onclick={controller.toggleHumburgerMenu} class='public-template-component__header__hamburger'>
                            <ImageAtomComponent src={controller.showHumburgetMenu() ? "/assets/icons/close-filled.svg" : "/assets/icons/hamburger-outlined.svg"} />
                        </ButtonAtomComponent>
                        <Show when={controller.state.showHeaderMenu}>
                            <MenuMoleculeComponent class='public-template-component__header__menu'>
                                <LinkAtomComponent href='/'>How it Works</LinkAtomComponent>
                                <LinkAtomComponent href='/about-us'>About Us</LinkAtomComponent>
                                <LinkAtomComponent href='/faq/general'>FAQ</LinkAtomComponent>
                                <Show when={controller.isAuthenticated()}>
                                    <LinkAtomComponent href='/account'>Account</LinkAtomComponent>
                                </Show>
                                <Show when={!controller.isAuthenticated()}>
                                    <LinkAtomComponent href='/login'>Login</LinkAtomComponent>
                                </Show>
                            </MenuMoleculeComponent>
                        </Show>
                    </BarAtomComponent>
                </Show>
                <Show when={controller.showHumburgetMenu()}>
                    <BarAtomComponent class='public-template-component__overlay' direction='vertical'>
                        <Show when={controller.state.showHeaderMenu}>
                            <MenuMoleculeComponent class='public-template-component__overlay__menu' direction='vertical'>
                                <LinkAtomComponent href='/'>How it Works</LinkAtomComponent>
                                <LinkAtomComponent href='/about-us'>About Us</LinkAtomComponent>
                                <LinkAtomComponent href='/faq/general'>FAQ</LinkAtomComponent>
                                <Show when={controller.isAuthenticated}>
                                    <LinkAtomComponent href='/account'>Account</LinkAtomComponent>
                                </Show>
                                <Show when={!controller.isAuthenticated}>
                                    <LinkAtomComponent href='/login'>Login</LinkAtomComponent>
                                </Show>
                            </MenuMoleculeComponent>
                        </Show>
                        <Show when={controller.state.showSidebar}>
                            <MenuMoleculeComponent class='public-template-component__overlay__menu' direction='vertical'>
                                <Show when={controller.countStore.get('numOfProperties') > 0}>
                                    <LinkAtomComponent href='/properties'>Properties ({controller.countStore.get('numOfProperties')})</LinkAtomComponent>
                                        <LinkAtomComponent href='/bookings' disabled={controller.countStore.get('numOfBookings') < 1}>Bookings ({controller.countStore.get('numOfBookings')})</LinkAtomComponent>
                                    </Show> 
                                    <Show when={controller.countStore.get('numOfStays') > 0}>
                                        <LinkAtomComponent href='/stays'>Stays ({controller.countStore.get('numOfStays')})</LinkAtomComponent>
                                    </Show>
                                <LinkAtomComponent href='/account'>Account</LinkAtomComponent>
                            </MenuMoleculeComponent>
                            <SeparatorAtomComponent class='public-template-component__overlay__separator' />
                            <MenuMoleculeComponent class='public-template-component__overlay__menu'>
                                <LinkAtomComponent href='/welcome'>
                                { !controller.countStore.get('numOfProperties') && !controller.countStore.get('numOfStays') ? 'Welcome' : ''}
                                { controller.countStore.get('numOfProperties') && !controller.countStore.get('numOfStays') ? 'Become a Guest' : ''}
                                { !controller.countStore.get('numOfProperties') && controller.countStore.get('numOfStays') ? 'Become a Host' : ''}
                                </LinkAtomComponent>
                            </MenuMoleculeComponent>
                            <MenuMoleculeComponent class='public-template-component__overlay__menu--last' direction='vertical'>
                            <SeparatorAtomComponent class='public-template-component__overlay__separator' />
                                <LinkAtomComponent href='/contact' disabled={true}>Contact</LinkAtomComponent>
                                <LinkAtomComponent href='/faq/general'>FAQ</LinkAtomComponent>
                                <LinkAtomComponent onClick={controller.logout}>Logout</LinkAtomComponent>
                            </MenuMoleculeComponent>
                        </Show>
                    </BarAtomComponent>
                </Show>
                <Show when={!controller.showHumburgetMenu()}>
                    <div class='public-template-component__main' classList={{'public-template-component__main--overflow':controller.state.showSidebar}} >
                        <Show when={controller.state.showSidebar}>
                            <BarAtomComponent class='public-template-component__main__sidebar'  direction='vertical'>
                                <MenuMoleculeComponent class='public-template-component__main__sidebar__menu' direction='vertical'>
                                    <Show when={controller.countStore.get('numOfProperties') > 0}>
                                        <LinkAtomComponent href='/properties'>Properties ({controller.countStore.get('numOfProperties')})</LinkAtomComponent>
                                            <LinkAtomComponent href='/bookings' disabled={controller.countStore.get('numOfBookings') < 1}>Bookings ({controller.countStore.get('numOfBookings')})</LinkAtomComponent>
                                        </Show> 
                                        <Show when={controller.countStore.get('numOfStays') > 0}>
                                            <LinkAtomComponent href='/stays'>Stays ({controller.countStore.get('numOfStays')})</LinkAtomComponent>
                                        </Show>
                                    <LinkAtomComponent href='/account'>Account</LinkAtomComponent>
                                </MenuMoleculeComponent>
                                <SeparatorAtomComponent class='public-template-component__main__sidebar__separator' />
                                <MenuMoleculeComponent class='public-template-component__main__sidebar__menu'>
                                    <LinkAtomComponent href='/welcome'>
                                    { !controller.countStore.get('numOfProperties') && !controller.countStore.get('numOfStays') ? 'Welcome' : ''}
                                    { controller.countStore.get('numOfProperties') && !controller.countStore.get('numOfStays') ? 'Become a Guest' : ''}
                                    { !controller.countStore.get('numOfProperties') && controller.countStore.get('numOfStays') ? 'Become a Host' : ''}
                                    </LinkAtomComponent>
                                </MenuMoleculeComponent>
                                <MenuMoleculeComponent class='public-template-component__main__sidebar__menu--last' direction='vertical'>
                                    <LinkAtomComponent href='/contact' disabled={true}>Contact</LinkAtomComponent>
                                    <LinkAtomComponent href='/faq/general'>FAQ</LinkAtomComponent>
                                    <LinkAtomComponent onClick={controller.logout}>Logout</LinkAtomComponent>
                                </MenuMoleculeComponent>
                            </BarAtomComponent>
                        </Show>
                        <div class='public-template-component__main__children' >
                            <Show when={controller.countStore.get('isLoaded') == true}>
                                <Routes/>
                            </Show>
                        </div>
                    </div>
                    <Show when={controller.state.showFooter}>
                        <FooterOrganismComponent class="public-template-component__footer"/>
                    </Show>
                </Show>
            </div>
        </PublicTemplateContext.Provider>
    )
}
