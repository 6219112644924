import { createStore } from "solid-js/store"
import { deepClone } from "../../app/utils.js"

class BaseObjectStore {
    
    constructor(initialState = {}) {
        const [state, setState] = createStore(initialState)
        this.state = state
        this.setState = setState
    }
    
    getState() {
        return this.state
    }
    
    getClone() {
        return deepClone(this.state)
    }

    get(key) {
        // console.log('BaseObjectStore.get', key, this.state[key])
        return this.state[key]
    }

    set(object) {
        // console.log('- - BaseObjectStore.set | object', object)

        this.setState(object)
    }
    
    merge(key, value) {
      // Use merge to preserve the living reference
      this.setState((prevState) => ({ [key]: value }));
    }

}

export default BaseObjectStore