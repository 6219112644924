import { render } from 'solid-js/web'
import './styles/main.scss'
import { Router } from '@solidjs/router'
import { ErrorBoundary } from 'solid-js'
import errorStore from './stores/error-store.js'
import ErrorModal from './app/modals/error-modal/error-modal.jsx'
import Error from './models/Error.js'
import Routes from './app/routes/router';
import PublicTemplateComponent from './app/components/templates/public-template/public-template-component.jsx'


render(() => (
    <Router>
        <PublicTemplateComponent>
            <For each={errorStore.getSession().errors}>
                {error => <ErrorModal error={error} onClose={() => errorStore.removeError(error)} />}
            </For>
            <ErrorBoundary fallback={(error) => {
                console.error(error)
                errorStore.addError(new Error({ message: error.message, stack: 'Application UI error, cannot build' }))
            }}>
            </ErrorBoundary>
        </PublicTemplateComponent>
    </Router>
), app)