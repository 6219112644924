import BaseObjectStore from "./base/base-object-store.js"

class SessionStore extends BaseObjectStore {
    constructor(session = {}) {
        super({
            errors: [],
        })
    }

    getSession() {
        return this.getState()
    }

    setSession(session) {
        this.set(session)
    }

    addError(error) {
        this.setState('errors', [...this.getState().errors, error])
    }

    removeError(error) {
        this.setState('errors', this.getState().errors.filter(e => e !== error))
    }

    clearErrors() {
        this.setState('errors', [])
    }
}

const sessionStore = new SessionStore()
export default sessionStore