const environment = import.meta.env;

export const app = {
    env: environment.VITE_APP_ENV,
    debug: environment.VITE_APP_DEBUG,
    hotReload: environment.VITE_APP_HOT_RELOAD
}

export const sso = {
    authUrl: environment.VITE_SSO_AUTH_URL,
    clientId: environment.VITE_SSO_CLIENT_ID,
    responseType: environment.VITE_SSO_RESPONSE_TYPE,
    state: environment.VITE_SSO_STATE,
    codeChallengeMethod: environment.VITE_SSO_CODE_CHALLENGE_METHOD,
}

export const api = {
    base: environment.VITE_APP_API_BASE,
    mode: environment.VITE_APP_API_MODE || environment.VITE_APP_ENV,
}

export const web = {
    base: environment.VITE_APP_WEB_BASE,
}

export const ext = {
    url: environment.VITE_APP_EXT_URL,
}

export default {
    api,
    app,
    web,
    sso,
    ext
}