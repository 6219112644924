class Error {
    #message
    #stack

    constructor(error) {
        this.#message = error.message
        this.#stack = error.stack
    }

    getMessage() {
        return this.#message
    }

    // TODO: Make errors bubble up the stack and display human-readable stack
    getStack() {
        return this.#stack
    }

    toString() {
        return this.#message
    }

    toJSON() {
        return {
            message: this.#message,
            stack: this.#stack,
        }
    }
}

export default Error