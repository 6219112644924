import LinkAtomComponent from '../../atoms/link-atom/link-atom-component';
import './footer-organism.scss';
import { useFooterOrganismComponentController } from './footer-organism-controller.js';


export default function FooterOrganismComponent() {
    const { footerSections } = useFooterOrganismComponentController()

    return (
        <div class="footer">
            <div class="footer__container">
                {footerSections.map((section, sectionIndex) => (
                    <div key={sectionIndex} class="footer__section">
                        <div class="footer__section-title">
                            {section.title}
                        </div>
                        <div class="footer__section-divider"></div>
                        {section.links.map((link, linkIndex) => (
                            <div key={linkIndex} class="footer__link-wrapper">
                                <LinkAtomComponent href={link.href} external={link.external} class='footer__link'>
                                    {link.text}
                                </LinkAtomComponent>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}
