// RIPPED FROM JAUNTR-EXT
class BaseBrowserStore {
    constructor(storeName, defaultValues) {
        // console.log('- - - BaseBrowserStore | constructor', storeName)
        this.storeName = storeName
        this.initializeDefaults(defaultValues)
    }

    // Helper method to create prefixed keys
    _getPrefixedKey(key) {
        return `${this.storeName}_${key}`
    }

    async initializeDefaults(defaultValues) {
        // console.log('- - - BaseBrowserStore | initializeDefaults', { storeName: this.storeName, defaultValues })
        for (let key in defaultValues) {
            if (await this.get(this._getPrefixedKey(key)) === undefined) {
                await this.set(this._getPrefixedKey(key), defaultValues[key])
            }
        }
    }

    // Instance method to get a value from browser storage
    async get(key) {
        return new Promise((resolve, reject) => {
            const prefixedKey = this._getPrefixedKey(key)
            try {
                const value = localStorage.getItem(prefixedKey);
                // console.log('- - - BaseBrowserStore | get', { storeName: this.storeName, value })
                resolve(value)
            } catch (error) {
                reject(error)
            }
        })
    }

    // Instance method to set a value in browser storage
    async set(key, value) {
        // console.log('- - - BaseBrowserStore | set', { storeName: this.storeName, key, value })
        return new Promise((resolve, reject) => {
            const prefixedKey = this._getPrefixedKey(key)
            try {
                localStorage.setItem(prefixedKey, value)
                resolve()
            } catch (error) {
                reject(error)
            }
        })
    }

    // Instance method to remove a value in browser storage
    async remove(key) {
        // console.log('- - - BaseBrowserStore | remove', { storeName: this.storeName, key })
        return new Promise((resolve, reject) => {
            const prefixedKey = this._getPrefixedKey(key)
            try {
                localStorage.removeItem(prefixedKey)
                resolve()
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default BaseBrowserStore
